import axios from 'axios';
//const baseUrl = '/api/login';
//const baseUrl = 'http://10.20.2.36:6081/api/login';
//!!!!!const baseUrl = 'http://mcadex.savcor.com:6081/api/login'; // TÄMÄ ON OIKEA!
//const baseUrl = 'https://mcadex.savcor.com/api/login'; // TÄMÄ ON OIKEA!

const baseUrl = 'https://opus.iottech.fi/api/login'; // TÄMÄ ON OIKEA!
//const baseUrl = 'http://172.16.0.103:7095/api/login'; // Kotitesti
//const baseUrl = 'http://127.0.0.1:7095/api/login'; 

const login = async (credentials) => {
    //console.log('heippa loginista')
    console.log('login-test', credentials)
    const response = await axios.post(baseUrl, credentials);
    console.log('login heippa response', response)
    return response.data
}

export default login;